// Import all files from a directory
function importAll(r) {
  r.keys().forEach(r);
}

/**
 * DoctorsHub Scripts
 */
import './main';

import '../styles/main.scss';
/**
 * DoctorsHub Images
 */
importAll(require.context('../images', true, /\.(png|jpe?g|svg|ico)$/));