/**
 * Initialize Alpine
 */
import Alpine from "alpinejs";
/**
 * Initialize htmx
 */
import htmx from 'htmx.org';
/**
 * Add licenser of htmx custom response HX-Trigger `showMessage`.
 */
import Swal from "sweetalert2";

window.Alpine = Alpine;
Alpine.start();

window.htmx = htmx;

/**
 * Reset Form after htmx swap
 */

document.body.addEventListener("success_response", () => {
    const modalEle = document.querySelector("dialog[data-modal-form]");
    const modalForm = modalEle?.querySelector("form");
    //close modal
    modalEle?.classList.remove("modal-open");
    //reset form
    modalForm?.reset();
});

window.Swal = Swal;

document.body.addEventListener("showMessages", (evt) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        showCloseButton: true,
        timerProgressBar: false,
        timer: 3000,
    });
    evt.detail.value?.forEach((message) => Toast.fire({
        icon: message.tags,
        title: message.title,
    }));
});

function closeModal() {
    document.querySelectorAll("[data-modal-close]")?.forEach((btn) => {
        btn.addEventListener("click", (e) => {
            const modalId = e.target.getAttribute("data-modal-close");
            document.getElementById(modalId).classList.remove("modal-open");
        });
    });
}

closeModal();

/**
 * Rebind listener after htmx swap
 */
window.addEventListener("htmx:afterSwap", async () => {
    closeModal();
    // Rebind Delete popup
    deletePopup();
    // close drawer
    closeDrawer();
});

/**
 * Listener of data-button-delete click attribute
 */
function deletePopup() {
    document.querySelectorAll("[data-button-delete]").forEach((btn) => {
        const url = btn.getAttribute("data-button-delete");
        btn.addEventListener("click", () => {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    htmx.ajax("post", url, btn);
                }
            });
        });
    });
}

deletePopup();

/**
 * HTMX Event Listener
 */
document.addEventListener("htmx:confirm", (e) => {
    if (e.target.hasAttribute('hx-confirm')) {
        e.preventDefault();
        Swal.fire({
            title: "Are you sure?",
            text: e.detail.question ?? "",
            icon: "warning",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Confirm",
        }).then((result) => {
            if (result.isConfirmed) {
                e.detail.issueRequest(true);
            }
        });
    }
});


/**
 * Menu Item handler
 */
document.addEventListener('DOMContentLoaded', () => {
    // Select all menus
    document.querySelectorAll('.menu').forEach((menu) => {
        menu.addEventListener('click', function (e) {
            const target = e.target;
            // Check if the clicked menu item is already active
            if (target.classList.contains('menu-item') && !target.classList.contains('active')) {
                // Remove 'active' class from all menu items in this menu
                menu.querySelectorAll('.menu-item').forEach(item => item.classList.remove('active'));
                // Add 'active' class to the clicked menu item
                target.classList.add('active');
            }
        });
    });
});

/**
 * Drawer
 */
const rightDrawerToggle = document.getElementById("speechrec-drawer");

function closeDrawer() {
    document.querySelectorAll("[data-drawer-toggle-right]").forEach((toggle) => {
        toggle.addEventListener("click", () => {
            if (rightDrawerToggle !== null) {
                rightDrawerToggle.checked = false;
            }
        });
    });
}

closeDrawer();

/**
 * Stripe
 */
import {loadStripe} from "@stripe/stripe-js";

loadStripe(process.env.STRIPE_API_KEY)
.then((stripe) => {
  window.stripe = stripe;
});

document.addEventListener("CLOSE_MODAL", () => {
    document.querySelectorAll(".modal").forEach((e) => e.classList.remove("modal-open"));
});

/**
 * Overlay
 */
document.addEventListener('htmx:configRequest', function(evt) {
    // Show the overlay when the request is triggered
    document.getElementById('overlay').style.display = 'flex';
});

document.addEventListener('htmx:afterRequest', function(evt) {
    // Hide the overlay after the request is complete
    document.getElementById('overlay').style.display = 'none';
    // Reset the progress bar
    htmx.find("#upload-progress").style.setProperty("--value", 0);
    htmx.find("#upload-progress").classList.add("hidden");
});

htmx.on("htmx:xhr:progress", (evt) => {
    htmx.find("#upload-progress").classList.remove("hidden");
    htmx.find("#upload-progress").style.setProperty("--value", evt.detail.loaded/evt.detail.total * 100);
});